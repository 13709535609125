import { render, staticRenderFns } from "./EditPortfolioParams.vue?vue&type=template&id=d78fe990&"
import script from "./EditPortfolioParams.vue?vue&type=script&lang=js&"
export * from "./EditPortfolioParams.vue?vue&type=script&lang=js&"
import style0 from "./EditPortfolioParams.vue?vue&type=style&index=0&id=d78fe990&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./EditPortfolioParams.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports