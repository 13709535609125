<i18n>
{
  "de": {
    "activeFeaturesTitle": "Aktivierte Features",
    "featurePvProfitability": "Wirtschaftlichkeit Photovoltaik",
    "featureKpi": "Kennzahlen",
    "featureHeatingFeasibility": "Machbarkeit Heizungsersatz",
    "featureHeatingMeasures": "Massnahmen Heizung",
    "featureEnvelopeMeasures": "Massnahmen Gebäudehülle",
    "featurePvMeasures": "Massnahmen Photovoltaik",
    "featureZonesMeasures": "Massnahmen Zonen und Flächen",
    "featureEnergyGridMeasures": "Massnahmen Stromkonfiguration",
    "featureAltCalibration": "Alternative Kalibrationsmethode",
    "enabled": "Aktiviert",
    "disabled": "Deaktiviert",
    "years": "Jahre",
    "operationCostsTitle": "Betriebskosten",
    "pvOperationCosts": "Photovoltaik",
    "energyPricesTitle": "Energiepreise",
    "elPriceGrid": "Strom",
    "elPriceFeedin": "Strom Rückspeisung",
    "elPriceInflation": "Strompreisteuerung",
    "eivTitle": "Einmalvergütung Photovoltaik",
    "eivMinCapacity": "Minimale Leistung",
    "eivLargeThreshold": "Leistungsgrenze Klein/Gross",
    "eivIntegratedBase": "Basis (Integriert)",
    "eivIntegratedPerKwpSmall": "Klein (Integriert)",
    "eivIntegratedPerKwpLarge": "Gross (Integriert)",
    "eivFreestandingBase": "Basis (Aufdach)",
    "eivFreestandingPerKwpSmall": "Klein (Aufdach)",
    "eivFreestandingPerKwpLarge": "Gross (Aufdach)",
    "restoreButtonLabel": "Standardwerte für die Nutzung neu laden",
    "pendingMessage": "Standardwerte werden neu geladen, bitte warten..."
  }
}
</i18n>

<template>
  <EditContainer :bind-form-state="formState" class="edit-portfolio-params" @container="onFormEvent">
    <h3 style="margin-top: 0">{{ $t('activeFeaturesTitle') }}</h3>
    <FormRow :label="$t('featurePvProfitability')">
      <CheckInput
        v-model="model.enabled_features.PV_PROFITABILITY"
        :edit="isEditing"
        :label="$t('enabled')"
        :active-label="$t('enabled')"
        :inactive-label="$t('disabled')"
      />
    </FormRow>
    <FormRow :label="$t('featureKpi')">
      <CheckInput
        v-model="model.enabled_features.KPI"
        :edit="isEditing"
        :label="$t('enabled')"
        :active-label="$t('enabled')"
        :inactive-label="$t('disabled')"
      />
    </FormRow>
    <FormRow :label="$t('featureHeatingFeasibility')">
      <CheckInput
        v-model="model.enabled_features.HEATING_FEASIBILITY"
        :edit="isEditing"
        :label="$t('enabled')"
        :active-label="$t('enabled')"
        :inactive-label="$t('disabled')"
      />
    </FormRow>
    <FormRow :label="$t('featureHeatingMeasures')">
      <CheckInput
        v-model="model.enabled_features.HEATING_MEASURES"
        :edit="isEditing"
        :label="$t('enabled')"
        :active-label="$t('enabled')"
        :inactive-label="$t('disabled')"
      />
    </FormRow>
    <FormRow :label="$t('featureEnvelopeMeasures')">
      <CheckInput
        v-model="model.enabled_features.ENVELOPE_MEASURES"
        :edit="isEditing"
        :label="$t('enabled')"
        :active-label="$t('enabled')"
        :inactive-label="$t('disabled')"
      />
    </FormRow>
    <FormRow :label="$t('featurePvMeasures')">
      <CheckInput
        v-model="model.enabled_features.PV_MEASURES"
        :edit="isEditing"
        :label="$t('enabled')"
        :active-label="$t('enabled')"
        :inactive-label="$t('disabled')"
      />
    </FormRow>
    <FormRow :label="$t('featureZonesMeasures')">
      <CheckInput
        v-model="model.enabled_features.ZONES_MEASURES"
        :edit="isEditing"
        :label="$t('enabled')"
        :active-label="$t('enabled')"
        :inactive-label="$t('disabled')"
      />
    </FormRow>
    <FormRow :label="$t('featureEnergyGridMeasures')">
      <CheckInput
        v-model="model.enabled_features.ENERGY_GRID_MEASURES"
        :edit="isEditing"
        :label="$t('enabled')"
        :active-label="$t('enabled')"
        :inactive-label="$t('disabled')"
      />
    </FormRow>
    <FormRow :label="$t('featureAltCalibration')">
      <CheckInput
        v-model="model.enabled_features.ALT_CALIBRATION"
        :edit="isEditing"
        :label="$t('enabled')"
        :active-label="$t('enabled')"
        :inactive-label="$t('disabled')"
      />
    </FormRow>
    <h3>{{ $t('operationCostsTitle') }}</h3>
    <FormRow :label="$t('pvOperationCosts')">
      <NumericInput
        v-model="model.operation_costs.pv"
        :units="'CHF/kWh'"
        :edit="isEditing"
        @validation="onValidation($event, 'operation-costs')"
      />
    </FormRow>
    <h3>{{ $t('energyPricesTitle') }}</h3>
    <FormRow :label="$t('elPriceGrid')">
      <NumericInput
        v-model="model.energy_prices.electricity.grid"
        :units="'CHF/kWh'"
        :edit="isEditing"
        @validation="onValidation($event, 'grid')"
      />
    </FormRow>
    <FormRow :label="$t('elPriceFeedin')">
      <NumericInput
        v-model="model.energy_prices.electricity.feedin"
        :units="'CHF/kWh'"
        :edit="isEditing"
        @validation="onValidation($event, 'feein')"
      />
    </FormRow>
    <FormRow :label="$t('elPriceInflation')">
      <NumericInput
        v-model="model.energy_prices.electricity.inflation"
        :units="'%'"
        :edit="isEditing"
        @validation="onValidation($event, 'inflation')"
      />
    </FormRow>
    <h3>{{ $t('eivTitle') }}</h3>
    <FormRow :label="$t('eivMinCapacity')">
      <NumericInput
        v-model="model.subsidies.pv_eiv.min_capacity"
        :units="'kWp'"
        :edit="isEditing"
        @validation="onValidation($event, 'min-capacity')"
      />
    </FormRow>
    <FormRow :label="$t('eivLargeThreshold')">
      <NumericInput
        v-model="model.subsidies.pv_eiv.large_capacity_threshold"
        :units="'kWp'"
        :edit="isEditing"
        @validation="onValidation($event, 'large-capacity-threshold')"
      />
    </FormRow>
    <FormRow :label="$t('eivIntegratedBase')">
      <NumericInput
        v-model="model.subsidies.pv_eiv.integrated_base"
        :units="'CHF'"
        :edit="isEditing"
        @validation="onValidation($event, 'integrated-base')"
      />
    </FormRow>
    <FormRow :label="$t('eivIntegratedPerKwpSmall')">
      <NumericInput
        v-model="model.subsidies.pv_eiv.integrated_per_kwp_small"
        :units="'CHF/kWp'"
        :edit="isEditing"
        @validation="onValidation($event, 'integrated-per-kwp-small')"
      />
    </FormRow>
    <FormRow :label="$t('eivIntegratedPerKwpLarge')">
      <NumericInput
        v-model="model.subsidies.pv_eiv.integrated_per_kwp_large"
        :units="'CHF/kWp'"
        :edit="isEditing"
        @validation="onValidation($event, 'integrated-per-kwp-large')"
      />
    </FormRow>
    <FormRow :label="$t('eivFreestandingBase')">
      <NumericInput
        v-model="model.subsidies.pv_eiv.freestanding_base"
        :units="'CHF'"
        :edit="isEditing"
        @validation="onValidation($event, 'freestanding-base')"
      />
    </FormRow>
    <FormRow :label="$t('eivFreestandingPerKwpSmall')">
      <NumericInput
        v-model="model.subsidies.pv_eiv.freestanding_per_kwp_small"
        :units="'CHF/kWp'"
        :edit="isEditing"
        @validation="onValidation($event, 'freestanding-per-kwp-large')"
      />
    </FormRow>
    <FormRow :label="$t('eivFreestandingPerKwpLarge')">
      <NumericInput
        v-model="model.subsidies.pv_eiv.freestanding_per_kwp_large"
        :units="'CHF/kWp'"
        :edit="isEditing"
        @validation="onValidation($event, 'freestanding-per-kwp-large')"
      />
    </FormRow>
    <ButtonWrapper v-if="!pending">
      <button class="button" @click="restoreUsageTypeDefaults">{{ $t('restoreButtonLabel') }}</button>
    </ButtonWrapper>
    <div v-else-if="error">
      {{ error }}
    </div>
    <div v-else>
      {{ $t('pendingMessage') }}
    </div>
  </EditContainer>
</template>

<script>
import { mapActions } from 'vuex'

import ApiFormMixin from '@/components/shared/forms/ApiFormMixin.vue'

import EditContainer from '@/components/shared/forms/EditContainer.vue'
import NumericInput from '@/components/shared/forms/NumericInput.vue'
import FormRow from '@/components/shared/forms/FormRow.vue'
import CheckInput from '@/components/shared/forms/CheckInput.vue'
import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'

export default {
  mixins: [
    ApiFormMixin,
    /* ApiFormMixin:
      - Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
      - Provides: model, isValid, isEditing, formState, onFormEvent, onValidation,
                  getBuildingPermission, getPortfolioPermission
    */
  ],

  components: {
    NumericInput,
    CheckInput,
    EditContainer,
    FormRow,
    ButtonWrapper,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      pending: false,
      error: null,
    }
  },

  watch: {
    portfolio() {
      this.resetModel()
    },
  },

  methods: {
    ...mapActions({
      updateParams: 'portfolio/updateParams',
      restoreUsageParams: 'portfolio/restoreUsageParams',
    }),

    resetModel() {
      this.model = JSON.parse(
        JSON.stringify({
          enabled_features: this.portfolio.enabled_features,
          operation_costs: this.portfolio.operation_costs,
          energy_prices: this.portfolio.energy_prices,
          subsidies: this.portfolio.subsidies,
        })
      )
    },

    async saveHandler() {
      await this.updateParams({
        id: this.portfolio.id,
        params: this.model,
      })
    },

    async restoreUsageTypeDefaults() {
      this.error = null
      this.pending = true
      try {
        await this.restoreUsageParams({
          id: this.portfolio.id,
        })
      } catch (e) {
        this.error = e
      }
      this.pending = false
    },
  },
}
</script>

<style>
.edit-portfolio-params {
  max-width: 600px;
}
</style>
